<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Errors</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="$store.state.errors.timeSelect"
        :items="timeItems"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Time"
      ></v-combobox>
      <v-combobox
        v-model="$store.state.errors.pageItemSelect"
        :items="pageItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Page"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="currentVersion"
        :items="_.sortBy($store.state.errors.versions, 'version').reverse()"
        item-text="version"
        item-value="version"
        label="Current Version"
        @change="initialize"
        hide-details
      >
      </v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headersOutPlayer"
      :items="$store.state.errors.all"
      class="elevation-1"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @click:row="editItem"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.createdDate="{ item }">{{
        new Date(item.createdDate * 1000) | formatDate
      }}</template>

      <template v-slot:item.activeScene="{ item }">
        <div v-for="(i, index) in item.activeScene.buckets" :key="index">
          {{ i.key }}
          -
          <span style="color:#f4ff49;">{{ i.doc_count }}</span>
        </div>
      </template>
      <template v-slot:item.version="{ item }">
        <div v-for="(i, index) in item.version.buckets" :key="index">
          <span>{{ i.key }}-</span>
          <span style="color:#f4ff49;">{{ i.doc_count }}</span>
        </div>
      </template>
      <template v-slot:item.os="{ item }">
        <div v-for="(i, index) in item.os.buckets" :key="index">
          <span>{{ i.key }}-</span>
          <span style="color:#f4ff49;">{{ i.doc_count }}</span>
        </div>
      </template>
      <template v-slot:item.condition="{ item }">
        <div v-for="(i, index) in item.condition" :key="index">
          <div>
            <span>{{ i.key }}-</span>
            <span style="color:#f4ff49;">{{ i.doc_count }}</span>
          </div>
        </div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        @input="pageClick"
        :total-visible="20"
        :length="$store.state.players.all.totalPage"
      ></v-pagination>
    </div>
    <v-row justify="center">
      <v-dialog v-model="playerIOdialog" max-width="1800px">
        <v-card>
          <v-card-title>
            <span class="headline">Error Details</span>
          </v-card-title>
          <v-jsoneditor
            v-model="$store.state.errors.error"
            :options="options"
            :plus="true"
            height="2800px"
          />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="playerIOdialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import VJsoneditor from "v-jsoneditor";

export default {
  components: {
    VJsoneditor,
  },
  data() {
    return {
      page: 1,
      currentVersion: {},
      versions: {},

      dialog: false,
      dialog1: false,
      playerIOdialog: false,
      time: { id: -1, value: "All" },
      dates: "2020-02-25",
      menu: false,
      messageModel: {},
      slotGiftModel: {},
      search: "",
      curretItem: {},
      select: { id: -1, value: "All" },
      playerSelect: { id: -1, value: "All" },
      sitSelect: { id: -1, value: "All" },
      pageCount: 0,
      currentPage: 1,
      itemsPerPage: 500,
      options: {
        mode: "code",
      },
      pagination: {
        sortBy: "name",
      },
      pageItems: [
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 2000, value: "2000" },
        { id: 5000, value: "5000" },
      ],
      timeItems: [
        { id: -1, value: "All" },
        { id: 15, value: "Last 15 minutes" },
        { id: 30, value: "Last 30 minutes" },
        { id: 60, value: "Last 1 hour" },
        { id: 1440, value: "Last 24 hours" },
        { id: 10080, value: "Last 7 Days" },
        { id: 43200, value: "last 30 Days" },
        { id: 129600, value: "last 90 Days" },
      ],
      items: [
        { id: -1, value: "All" },
        { id: false, value: "Connect" },
        { id: true, value: "Disconnect" },
      ],
      playerItems: [
        { id: -1, value: "All" },
        { id: true, value: "Bot Players" },
        { id: false, value: "Real Players" },
      ],
      sitItems: [
        { id: -1, value: "All" },
        { id: true, value: "Sit In" },
        { id: false, value: "Sit Out" },
      ],

      headersOutPlayer: [
        { text: "errors", value: "message" },
        { text: "total", value: "total" },
        { text: "createdDate", value: "createdDate" },
        { text: "userTotal", value: "userTotal" },
        { text: "deviceTotal", value: "deviceTotal" },
        { text: "activeScene", value: "activeScene" },
        { text: "version", value: "version" },
        { text: "os", value: "os" },
      ],
    };
  },
  computed: {},

  methods: {
    save(date) {
      this.$refs.menu.save(date);
      this.initialize();
    },
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    getColor(item) {
      if (item.disconnectChip > item.connectChip) return "lime";
      if (item.disconnectChip < item.connectChip) return "#FF6D6D";
    },
    getTimeAgo(sec) {
      let a = new Date();
      a.setSeconds(a.getSeconds() - sec);
      return a;
    },
    async initialize() {
      await this.$store.dispatch("errors/getVersion");
      await this.$store.dispatch("errors/getErrors", {
        search: this.search,
        date: this.dates,
        time: this.time,
        page: this.currentPage,
        version: this.currentVersion,
      });
    },
    addMessage(item) {
      this.curretItem = item;
      this.dialog = true;
    },
    sendSlotGiftDialog(item) {
      this.curretItem = item;
      this.dialog1 = true;
    },

    getImage(item) {
      return `@/assets/${item}.png`;
      // return `@/assets/${item}.png`;
    },

    async editItem(item) {
      await this.$store.dispatch("errors/getErrorDetail", item.message);
      this.playerIOdialog = true;
    },
    async disconnectItem(item) {
      await this.$store.dispatch("errors/disconnectPlayer", item.connectionId);
    },
  },

  async mounted() {
    await this.$store.dispatch("apis/getAllVersions");
    this.currentVersion = this.$store.state.apis.versions.find(
      (m) => m.id == "currentVersion"
    );
    await this.$store.commit("errors/getParameters");
    await this.initialize();
  },
};
</script>

<style>
.v-tooltip__content {
  opacity: 1 !important;
}
.jv-container {
  background: #0000005c !important;
}
.jsoneditor-field {
  color: #b7b3c3 !important;
}
a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}
div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}
.ace-jsoneditor .ace_variable,
.ace-jsoneditor .ace_gutter-cell {
  color: #fff !important;
}
div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}
.ace-jsoneditor .ace_scroller,
.ace-jsoneditor .ace_gutter {
  background: #000 !important;
}
</style>
